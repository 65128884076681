import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Button } from '@tradesorg/component-library-waas-templates.base-button';
import { InputDropdown } from '@tradesorg/component-library-waas-templates.base-input-dropdown';
import { InputTemplate } from '@tradesorg/component-library-waas-templates.base-input-template';
import './index.scss';

const RequestAQuoteModal = ({openRequestQuote, setOpenRequestQuote, props}) => {
    const [form, setForm]= useState({
        search: '',
        phone: '',
        email: ''
    })

    const handleChange = (e) => {
		setForm({
            ...form,
            [e.target.name]: e.target.value
        })
	};


    const displayClass = openRequestQuote ? 'display-block' : 'display-none';

    return (
        <Popover className={`mobile-request-modal md-1:modal ${displayClass}`}>
            <>
                <div
                    className="absolute inset-0 z-40 shadow pointer-events-none 
                    md-1:modal-main md-1:flex md-1:flex-col md-1:min-h-screen justify-center items-center md-1:pt-4 md-1:px-4"
                    aria-hidden="true"
                />
                <Transition
                    show={openRequestQuote}
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        static
                        className="modal-main md-1:flex md-1:flex-col justify-center items-center md-1:pt-4 md-1:px-4"
                    >
                        <div className="md-1:h-auto rounded-lg shadow-lg ring-1 pt-6 px-5
                        ring-black ring-opacity-5 bg-white">
                            <div class="py-9 flex flex-wrap flex-col justify-center items-center items-center">
                                <div class="flex flex-col items-center">
                                    <div class="
                                        text-sm
                                        flex
                                        text-sm
                                        flex-col
                                        justify-center
                                        items-center
                                        text-gray-500"
                                    >
                                    </div>
                                    <h2 class="mt-4 text-2xl leading-8 text-center font-semibold md-1:text-left">Request a Quote</h2>
                                </div>
                                <div class="py-1 px-4">
                                    <p class="text-center text-xs md-1:px-12">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </div>
                            </div>
                            <InputDropdown
                                classNames={{ input: 'border-gray-300' }}
                                rounded={true}
                                name="search"
                                id="search"
                                placeholder="What type of project do you have?"
                                value={ form.search }
                                onChange={handleChange}
                            />
                            <InputTemplate
                                classNames={{ wrapper: 'mt-7 mb-10px' }}
                                rounded={true}
                                name="search"
                                id="search"
                                placeholder="First and last name"
                                value={form.search}
                                onChange={handleChange}
                                LeftComponent={
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.8779 18.6996L14.0681 12.8897C15.17 11.5293 15.8334 9.79975 15.8334 7.9167C15.8334 3.55145 12.2819 0 7.91666 0C3.55141 0 0 3.55145 0 7.9167C0 12.2819 3.55145 15.8334 7.9167 15.8334C9.79975 15.8334 11.5293 15.17 12.8897 14.0681L18.6996 19.878C18.8623 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM7.9167 14.1667C4.47024 14.1667 1.66668 11.3631 1.66668 7.9167C1.66668 4.47024 4.47024 1.66668 7.9167 1.66668C11.3631 1.66668 14.1667 4.47024 14.1667 7.9167C14.1667 11.3631 11.3631 14.1667 7.9167 14.1667Z"
                                            fill="#6e4bd9"
                                        />
                                    </svg>
                                }
                            />
                            <div className="grid grid-cols-1 md-1:grid-cols-2">
                                <InputTemplate
                                    classNames={{ wrapper: 'md-1:mr-10px' }}
                                    rounded={true}
                                    name="phone"
                                    id="phone"
                                    placeholder="Phone"
                                    value={form.phone}
                                    onChange={handleChange}
                                />
                                <InputTemplate
                                    classNames={{ wrapper: '' }}
                                    rounded={true}
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    value={form.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <Button
                                onClick={() => {}}
                                rounded
                                className="h-14 text-white bg-purple px-8 py-4 rounded text-sm font-semibold w-full mt-9"
                                >
                                Request a Quote
                                
                            </Button>   
                            <Button
                                onClick={() => {setOpenRequestQuote(false)}}
                                rounded
                                className="h-14 text-white bg-white text-gray-500 px-8 py-4 rounded text-sm font-semibold w-full"
                                >
                                Cancel
                            </Button>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
        </Popover>
    );
};

export default RequestAQuoteModal;
